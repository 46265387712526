import { Button, Col, Form, Modal, Radio, Row, Spin } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { PUBLIC_IMAGE_URL } from '../../../utils';
import styles from './index.less';
import type { LaunchStore } from './store';
import { getPlatformIcon } from './store';

// 一键铺货弹窗
const LaunchModal: React.FC<{ store: LaunchStore; }> = observer((props) => {
  const {
    visible,
    closeModal,
    setRef,
    loading,
    modalLoading,
    platform,
    onClickPlatform,
    refreshShop,
    shopList,
    currentPlatformType,
    jumpToShopManage,
    onOk,
    currentSelectShopId,
    frontOrBack,
  } = props.store;
  return (
    <Modal
      className={`${styles.launch} ${frontOrBack === 'front' ? styles.front : styles.back}`}
      confirmLoading={loading}
      maskClosable={false}
      onCancel={closeModal}
      onOk={onOk}
      open={visible}
      title="一键铺货"
      width={631}
    >
      <Spin spinning={modalLoading}>
        <div className={styles.platformContainer}>
          <label className={styles.label}>
            铺货平台:
          </label>
          <div style={{ flex: 1 }}>
            <Row gutter={[
              32,
              42,
            ]}
            >
              {platform?.map((el) => {
                return (
                  <Col key={el.platformType}>
                    <Platform
                      currentPlatformType={currentPlatformType}
                      name={el.platformTypeName}
                      onClickPlatform={onClickPlatform}
                      picUrl={getPlatformIcon(el.platformType)}
                      type={el.platformType}
                    />
                  </Col>
                );
              })}
            </Row>
          </div>
        </div>
        <div>
          <div className={styles.addShop}>
            <label className={styles.label}>
              铺货店铺:
            </label>
            <Button
              className={frontOrBack === 'front' ? styles.frontButton : null}
              ghost
              onClick={jumpToShopManage}
              type="primary"
            >
              +添加店铺
            </Button>
            <img
              className={styles.refresh}
              onClick={refreshShop}
              src={`${PUBLIC_IMAGE_URL}${frontOrBack === 'front' ? 'reload' : 'reloadBlue'}.png`}
            />
          </div>
          <div className={styles.shopContainer}>
            <Form
              initialValues={{ shop: currentSelectShopId }}
              ref={setRef}
            >
              <Form.Item
                name="shop"
                rules={[
                  {
                    required: true,
                    message: '请选择铺货店铺',
                  },
                ]}
              >
                <Radio.Group>
                  <Row gutter={[
                    0,
                    20,
                  ]}
                  >
                    {
                      shopList.map((el) => {
                        return (
                          <Col
                            key={el.shopId}
                            span={12}
                          >
                            <Radio
                              disabled={el?.authStatus === 2}
                              value={el.shopId}
                            >
                              <span title={el.shopName}>
                                {ellipsis(el.shopName)}
                              </span>
                              {el?.authStatus === 2 && (
                                <span className={styles.expired}>
                                  {el.authStatusName}
                                </span>
                              )}

                            </Radio>
                          </Col>
                        );
                      })
                    }
                  </Row>
                </Radio.Group>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Spin>
    </Modal>
  );
});

const Platform: React.FC<{
  picUrl: string;
  name: string;
  selectedNum?: number;
  type: number;
  currentPlatformType: number;
  onClickPlatform: (platformType: number) => void;
}> = observer((props) => {
  const {
    picUrl = '',
    name = '',
    onClickPlatform,
    selectedNum,
    type,
    currentPlatformType,
  } = props;
  return (
    <div className={styles.platform}>
      <div
        className={`${styles.platformImg} ${currentPlatformType === type ? `${styles.activePlatform}` : ''}`}
        onClick={() => onClickPlatform(type)}
      >
        <img
          className={styles.platformIcon}
          height={76}
          src={picUrl}
          width={76}
        />
      </div>
      {
        currentPlatformType === type && Boolean(selectedNum) && (
          <div className={styles.selected}>
            {selectedNum}
          </div>
        )
      }
      <div className={styles.name}>
        {name}
      </div>
    </div>
  );
});

const ellipsis = (str: string, maxSize = 10): string => {
  if (!str) {
    return '';
  }
  if (str.length > 10) {
    return `${str.slice(0, maxSize + 1)}...`;
  }
  return str;
};

export { LaunchModal };
