/**
 * 底部展示公司及备案信息
 */
import React from 'react';
import styles from './index.less';

const bottomCopyText = '江苏衫数科技集团有限公司© 版权所有 | 苏ICP备2022001362号-1';
export const Footer = () => {
  return (
    <div className={styles.footerWrap}>
      {bottomCopyText}
    </div>
  );
};
