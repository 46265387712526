import type { MenuProps } from 'antd';
import { Dropdown } from 'antd';
import type { BaseData, PaginationData } from 'egenie-utils';
import { request } from 'egenie-utils';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { api, checkLoginStatus, isDistributor, PUBLIC_IMAGE_URL } from '../../../utils';
import styles from './index.less';
import type { User } from './interface';
import { Store } from './store';
import NotificationDrawer from '../notificationDrawer';

const store = new Store();

interface Props {
  className?: string;
}

// 最顶部导航组件
const Navigation = observer((props: Props) => {
  const {
    toogleDrawer,
    unMessageNum,
    getUnReadMessage,
  } = store;
  const [
    loginStatus,
    setLoginStatus,
  ] = useState(false); // 登录状态

  const [
    userInfo,
    setUserInfo,
  ] = useState({} as User); // 用户信息

  const [
    shoppingCartNum,
    setShoppingCartNum,
  ] = useState(0); // 进货车数量
  const [
    unSendOrderListNum,
    setUnSendOrderListNum,
  ] = useState(0); // 未代发订单数量

  const [
    collectArrowDown,
    setCollectArrowDown,
  ] = useState(true); // 下拉箭头方向

  const [
    shopWorkArrowDown,
    setShopWorkArrowDown,
  ] = useState(true); // 下拉箭头方向

  const [
    appArrowDown,
    setAppArrowDown,
  ] = useState(true); // 下拉箭头方向
  const isDistributorType = isDistributor();

  const getUserInfo = async() => {
    const loginStatus = await checkLoginStatus();
    setLoginStatus(loginStatus);
    if (!loginStatus) {
      return;
    }
    const info: User = await request({ url: api.user });
    setUserInfo(info);
    getShoppingCartNum();
  };

  const getShoppingCartNum = async(): Promise<void> => {
    const res: BaseData<number> = await request({ url: api.getShoppingNumUrl });
    setShoppingCartNum(res.data);
  };

  const getUnSendOrderList = (): void => {
    request<PaginationData>({
      url: api.queryShopOrderList,
      method: 'post',
      data: {
        page: 1,
        pageSize: 10,
        proxySendStatus: 1,
      },
    })
      .then((res) => {
        setUnSendOrderListNum(res.data.totalCount);
      });
  };

  const init = async() => {
    const loginStatus = await checkLoginStatus();
    if (loginStatus) {
      getShoppingCartNum();
      getUnReadMessage();
      getUnSendOrderList();
    }
  };

  useEffect(() => {
    getUserInfo();
    init();
  }, []);

  const logout = async() => {
    try {
      await request({ url: api.logout });
    } finally {
      window.location.reload();
    }
  };

  const gotoPage = (url: string, needLogin?: boolean) => {
    if (needLogin && !loginStatus) {
      window.location.assign('/egenie-ts-vogue/login');
      return;
    }
    window.open(url);
    setCollectArrowDown(true);
  };

  const assignPage = (url: string) => {
    window.location.assign(url);
  };

  const jumpToPage = (jumpTo: string) => {
    if (!loginStatus) {
      window.location.assign(`/egenie-ts-vogue/login?jumpTo=${jumpTo}`);
      return;
    }
    setShopWorkArrowDown(true);
    if (userInfo.tenantType?.includes('100001')) {
      const jumpParam = jumpTo && jumpTo !== 'backstage' ? jumpTo : '';
      window.open(`${api.switchToErpHome}&jumpTo=${jumpParam}`);
    } else {
      window.open(`/egenie-erp-home/shjHome?jumpTo=${jumpTo}`);
    }
  };

  const jumpToShopOrderPage = (): void => {
    if (!loginStatus) {
      window.location.assign('/egenie-ts-vogue/login');
      return;
    }

    let url = '';
    if (userInfo.tenantType?.includes('100001')) {
      url = `${api.switchToErpHome}&jumpTo=shopOrder`;
    } else {
      url = '/egenie-erp-home/shjHome?jumpTo=shopOrder';
    }
    if (unSendOrderListNum > 0) {
      url += '&proxySendStatus=1';
    }
    window.open(url);
  };

  const collectionMenu: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <div
          className={styles.menuItem}
          onClick={() => jumpToPage('favoriteStyles')}
        >
          收藏的款式
        </div>
      ),
    },
    {
      key: '2',
      label: (
        <div
          className={styles.menuItem}
          onClick={() => gotoPage('/egenie-ts-vogue/cooperationStall/index', true)}
        >
          合作档口
        </div>
      ),
    },
  ];

  const shopWorkMenu: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <div
          className={styles.menuItem}
          onClick={() => jumpToPage('myOrder')}
        >
          我的订单
        </div>
      ),
    },
    {
      key: '2',
      label: (
        <div
          className={styles.menuItem}
          onClick={() => jumpToPage('myStyle')}
        >
          我的款式
        </div>
      ),
    },
  ];

  const appMenu: MenuProps['items'] =
    [
      {
        key: '1',
        label: (
          <div className={styles.menuItem}>
            <div className={styles.popoverImgWrap}>
              <CodeImg
                imgUrl={`${PUBLIC_IMAGE_URL}baokuanAppCode.png`}
                title="扫码下载APP"
              />
              <CodeImg
                imgUrl={`${PUBLIC_IMAGE_URL}shanHaiJinMiniProgramCode.jpg`}
                title="扫码打开小程序"
              />
            </div>
          </div>
        ),
      },
    ];
  if (isDistributorType) {
    return null;
  }
  return (
    <div className={`${styles.navigation} ${props.className}`}>
      <div className={styles.content}>
        {loginStatus
          ? (
            <div>
              <i className={`${styles.userIcon} icon-yh01`}/>
              <span className={styles.userInfo}>
                [
                {userInfo?.name}
                ]
              </span>
              <span
                className={`${styles.btn} ${styles.ml8}`}
                onClick={logout}
              >
                退出
              </span>
            </div>
          )
          : (
            <div>
              <span
                className={styles.btn}
                onClick={() => assignPage('/egenie-ts-vogue/login')}
              >
                请登录
              </span>
              <span
                className={`${styles.btn} ${styles.ml8}`}
                onClick={() => assignPage('/egenie-ts-vogue/register')}
              >
                免费注册
              </span>
            </div>
          )}
        <div>
          <span
            className={`${styles.btn} ${styles.colorFB071F}`}
            onClick={() => assignPage('/egenie-ts-vogue/homePage/index')}
          >
            衫海精首页
          </span>
          <span
            className={`${styles.btn} ${styles.ml30}`}
            onClick={() => gotoPage('/egenie-ts-vogue/shoppingCart/index', true)}
          >
            进货车
            <div className={styles.numWrap}>
              <div className={`${styles.shoppingNumCommon} ${shoppingCartNum > 99 ? styles.shoppingNumMore : styles.shoppingNum}`}>
                {shoppingCartNum > 99 ? '99+' : shoppingCartNum}
              </div>
            </div>
          </span>
          <Dropdown
            className={`${styles.btn} ${styles.ml30}`}
            menu={{ items: collectionMenu }}
            onOpenChange={() => setCollectArrowDown(!collectArrowDown)}
            overlayClassName={styles.popMenu}
          >
            <span>
              收藏夹
              <i className={`${styles.arrowIcon} icon-arrow_pulldown`}/>
            </span>
          </Dropdown>

          <Dropdown
            className={`${styles.btn} ${styles.ml30}`}
            menu={{ items: shopWorkMenu }}
            onOpenChange={() => setShopWorkArrowDown(!shopWorkArrowDown)}
            overlayClassName={styles.popMenu}
          >
            <span
              className={`${styles.btn} ${styles.ml30}`}
              onClick={() => jumpToPage('backstage')}
            >
              商家工作台
              <i className={`${styles.arrowIcon} icon-arrow_pulldown`}/>
            </span>
          </Dropdown>
          <span
            className={`${styles.btn} ${styles.ml30}`}
            onClick={jumpToShopOrderPage}
          >
            店铺订单
            <div className={styles.numWrap}>
              <div className={`${styles.shoppingNumCommon} ${unSendOrderListNum > 99 ? styles.shoppingNumMore : styles.shoppingNum}`}>
                {unSendOrderListNum > 99 ? '99+' : unSendOrderListNum}
              </div>
            </div>
          </span>
          <span
            className={`${styles.btn} ${styles.ml30}`}
            onClick={() => toogleDrawer(true)}
          >
            消息
            <div className={styles.numWrap}>
              <div className={`${styles.shoppingNumCommon} ${unMessageNum > 99 ? styles.shoppingNumMore : styles.shoppingNum}`}>
                {unMessageNum > 99 ? '99+' : unMessageNum}
              </div>
            </div>
          </span>
          <Dropdown
            className={`${styles.btn} ${styles.ml30}`}
            menu={{ items: appMenu }}
            onOpenChange={() => setAppArrowDown(!appArrowDown)}
            overlayClassName={styles.popMenu}
            placement="bottomRight"
          >
            <span>
              手机版
              <i className={`${styles.arrowIcon} icon-arrow_pulldown`}/>
            </span>
          </Dropdown>
        </div>
      </div>
      {/* <MessageDrawer */}
      {/*   store={store} */}
      {/* /> */}
      <NotificationDrawer
        store={store.notificationCenterStore}
        systemType={1000100}
      />
    </div>
  );
});

// 二维码图片
const CodeImg = (props: { title: string; imgUrl: string; }) => {
  const {
    title,
    imgUrl,
  } = props;
  return (
    <div className={styles.imgWrap}>
      <img
        alt=""
        src={imgUrl}
      />
      <span>
        {title}
      </span>
    </div>
  );
};

export { Navigation };
