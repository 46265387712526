import type { FormInstance } from 'antd';
import { message, Modal } from 'antd';
import type { BaseData } from 'egenie-common';
import { request } from 'egenie-common';
import { action, observable, computed } from 'mobx';
import { nanoid } from 'nanoid';
import douyin from '../../../assets/images/douyin.png';
import kuaishou from '../../../assets/images/kuaishou.png';
import pdd from '../../../assets/images/pdd.png';
import taobao from '../../../assets/images/taobao.png';
import tianmao from '../../../assets/images/tianmao.png';
import douyinProvider from '../../../assets/images/douyinProvider.png';
import weixin from '../../../assets/images/weixin.png';
import { api, checkLoginStatus } from '../../../utils';
import { SHELVE_URL } from '../../myStyle/constant';
import styles from './index.less';
import type { Platform, ShelveResult, ShopVos, User } from './interface';

export const getPlatformIcon = (type: number): string => {
  switch (type) {
    case 17:
      return pdd;
    case 21:
      return douyin;
    case 1:
      return taobao;
    case -100:
      return tianmao;
    case 22:
      return kuaishou;
    case 32:
      return douyinProvider;
    case 31:
      return weixin;
  }
  return '';
};
export class LaunchStore {
  constructor({ parent }) {
    this.parent = parent;
  }

  @observable public parent;

  @observable public visible = false;

  @observable public loading = false;

  @observable public modalLoading = false;

  @observable public dynamicIds: number | string;

  @observable public goodsId: number;

  @observable public ref: FormInstance;

  @observable public platform: Platform[] = [];

  @observable public currentPlatformType: number;

  @observable public currentPicGroupId: number;

  @observable public shopList: ShopVos[] = [];

  @observable public frontOrBack: 'front' | 'back';

  @observable private smallClient = true; // 是否为小客户

  @computed public get currentSelectShopId() {
    return this.shopList.find((item) => item.authStatus !== 2)?.shopId || 0;
  }

  // 已选店铺数
  // @computed public get selectedNum() {
  //   return this.selectedShop.length;
  // }

  // frontOrBack 用于判断是从前台的一键铺货还是后台的一键铺货
  @action public openModal = async(goodsId: number, dynamicIds: number | string, frontOrBack?: 'front' | 'back'): Promise<void> => {
    if (typeof this.parent?.lunchLoading === 'boolean') {
      this.parent.lunchLoading = true;
    }
    this.platform = await this.queryShopList();
    if (typeof this.parent?.lunchLoading === 'boolean') {
      this.parent.lunchLoading = false;
    }

    /* 判断是否有未失效的关联店铺 */
    const showModal = this.platform?.reduce((pre, cur) => {
      return pre + (cur.pcShopVos?.length || 0);
    }, 0);

    // 判断前台还是后台, 没传就是后台
    if (!frontOrBack) {
      this.frontOrBack = 'back';
    } else {
      this.frontOrBack = frontOrBack;
    }

    if (showModal) {
      this.currentPlatformType = this.platform[0]?.platformType;
      this.shopList = this.platform[0]?.pcShopVos || [];
      this.ref?.setFieldsValue({ shop: this.currentSelectShopId });
      this.dynamicIds = dynamicIds;
      this.goodsId = goodsId;
      this.queryUserType();
      this.visible = true;
    } else {
      const modal = Modal.confirm({});
      modal.update({
        title: '还没有授权的店铺，是否前往店铺管理添加？',
        getContainer: document.getElementById('modal'),
        className: `${frontOrBack === 'front' ? styles.confirm : ''}`,
        okText: '立即前往',
        onOk: this.jumpToShopManage,
      });
    }
  };

  @action public closeModal = (e?): void => {
    e && e.stopPropagation();
    Object.assign(this, {
      visible: false,
      goodsId: null,
      currentPlatformType: null,
      shopList: [],
      platform: [],
      loading: false,
      modalLoading: false,
      selectedShop: [],
    });
  };

  @action public setRef = (ref: FormInstance): void => {
    this.ref = ref;
  };

  @action public onOk = (e?): void => {
    e && e.stopPropagation();
    this.ref?.validateFields().then(async(res) => {
      this.loading = true;
      const { shop } = res;

      // 处理店铺数据
      const shopList = this.shopList.filter((el) => {
        return el.shopId === shop;
      });

      try {
        // 将款式加入我的款式
        const shelveRes: BaseData<ShelveResult> = await request({
          url: api.submitPublish,
          method: 'post',
          data: {
            dynamicIds: '',
            goodsId: this.goodsId,
            shopList,
          },
        });
        const gmsGoodsId = shelveRes.data?.gmsGoodsInfoVos[0]?.gmsGoodsId;
        message.success(shelveRes.info || '操作成功');

        const shopId = shopList[0].shopId;

        // 检查授权
        const checkAuthRes: BaseData = await request({
          url: api.validePermission,
          method: 'POST',
          data: { shopId },
        });

        if (checkAuthRes.data) {
          // 前后台不同打开方式不同
          if (this.frontOrBack === 'back') {
            const tempPageId = nanoid(5);
            console.log(SHELVE_URL[this.currentPlatformType](gmsGoodsId, shopId, tempPageId));
            window.top.egenie.openTab(SHELVE_URL[this.currentPlatformType](gmsGoodsId, shopId, tempPageId), tempPageId, '上架');
          } else {
            if (this.smallClient) {
              window.open(`/egenie-erp-home/shjHome?jumpTo=shelvesNew&currentPlatformType=${this.currentPlatformType}&gmsGoodsId=${gmsGoodsId}&shopId=${shopId}`); // 跳转到上架
            } else {
              window.open(`/api/iam/module/switch?module=100001&forwardUrl=/egenie-erp-home/index?jumpTo=shelvesNew&currentPlatformType=${this.currentPlatformType}&gmsGoodsId=${gmsGoodsId}&shopId=${shopId}`);
            }
          }
        } else {
          // 未授权
          Modal.confirm({
            title: '该店铺还未授权，请进行授权',
            okText: '去授权',
            getContainer: document.getElementById('modal'),
            className: `${this.frontOrBack === 'front' ? styles.confirm : ''}`,
            onOk: () => {
              return request<BaseData<any>>({ url: `${api.gotoAuth}/${shopId}` }).then((res) => {
                window.open(res.data);
              });
            },
          });
        }
        this.closeModal();
        this.parent?.egGridModel?.onQuery();
      } catch (e) {
        console.log(`提交错误---${e}`);
      } finally {
        this.loading = false;
      }
    });
  };

  // 刷新店铺列表
  @action public refreshShop = (): void => {
    this.queryShopList().then((platform) => {
      this.platform = platform;
      const platformItem = this.platform.find((el) => el.platformType === this.currentPlatformType);
      this.shopList = platformItem?.pcShopVos;

      // this.currentSelectShopId = this.shopList[0]?.shopId;
      // this.currentSelectShopId = this.shopList.find((item) => item.authStatus !== 2)?.shopId;

      this.ref?.setFieldsValue({ shop: this.currentSelectShopId });
    });
  };

  // 请求平台列表
  @action public queryShopList = async(): Promise<Platform[]> => {
    this.modalLoading = true;
    const res: BaseData<Platform[]> = await request({
      url: api.getClassifyShop,
      method: 'post',
    });
    this.modalLoading = false;
    return Promise.resolve(res.data);
  };

  // 切换平台列表
  @action public onClickPlatform = (platformType: number): void => {
    this.ref.resetFields();
    this.currentPlatformType = platformType;
    const platformItem = this.platform.find((el) => el.platformType === platformType);
    this.shopList = platformItem?.pcShopVos;
    this.ref?.setFieldsValue({ shop: this.currentSelectShopId });
  };

  @action public jumpToShopManage = (): void => {
    // 前后台不同打开方式不同
    if (this.frontOrBack === 'back') {
      if (this.smallClient) {
        window.top.egenie.openTab('/egenie-ts-vogue/shopAuthorization/index', '755', '店铺授权管理');
      } else {
        window.top.egenie.openTab('/egenie-ts-baseinfo/shopManage/index', '2695', '店铺管理');
      }
    } else {
      if (this.smallClient) {
        window.open('/egenie-erp-home/shjHome?jumpTo=shopManage'); // 跳转到小客户店铺管理
      } else {
        window.open('/api/iam/module/switch?module=100001&forwardUrl=/egenie-erp-home/index?jumpTo=shopManage'); // 跳转大客户店铺管理
      }
    }
  };

  /* 判断用户类型 */
  @action public queryUserType = async(): Promise<void> => {
    const loginStatus = await checkLoginStatus();
    if (!loginStatus) {
      return;
    }
    const info: User = await request({ url: api.user });
    if (info.tenantType?.includes('100001')) {
      this.smallClient = false;
    } else {
      this.smallClient = true;
    }
  };
}

