/**
 * 底部四个宣传logo
 */
import React from 'react';
import { PUBLIC_IMAGE_URL } from '../../../utils';
import styles from './index.less';

const logoList = [
  {
    name: '全国货源',
    url: `${PUBLIC_IMAGE_URL}supplyGoods.png`,
  },
  {
    name: '一键铺货',
    url: `${PUBLIC_IMAGE_URL}keyGoods.png`,
  },
  {
    name: '履约保障',
    url: `${PUBLIC_IMAGE_URL}guarantee.png`,
  },
  {
    name: '无忧退货',
    url: `${PUBLIC_IMAGE_URL}safeReturn.png`,
  },
];

export const FooterLogo = () => {
  return (
    <div className={styles.footerLogoWrap}>
      {
        logoList.map((item) => {
          const { name, url } = item;
          return (
            <div
              className={styles.imgWrap}
              key={name}
            >
              <img
                alt=""
                src={url}
              />
              <span>
                {name}
              </span>
            </div>
          );
        })
      }
    </div>
  );
};
