import React from 'react';
import styles from './index.less';

export const NoLackTag: React.FC<{ height?: string; }> = (props) => {
  return (
    <div
      className={styles.noLackTag}
      style={{
        height: props.height,
        lineHeight: props.height,
      }}
    >
      不缺货
    </div>
  );
};
